{
    "added": "Ajouté",
    "back": "Retour",
    "bar_categories": {
        "title": "Catégories"
    },
    "cart": {
        "active": "Chariots actifs",
        "booked": {
            "help": "Ces éléments sont confirmés et réservés par vous et donc par nous. Ils sont affichés ici à titre indicatif. Veuillez nous contacter si vous souhaitez apporter des modifications."
        },
        "checkout_button": "Sortie de caisse",
        "checkout_header": "Panier d'achat",
        "checkout_one": "Checkout ({{count}})",
        "checkout_order": "Commande en cours",
        "checkout_order_id": "Commande en cours {{id}}",
        "checkout_other": "Checkout ({{count}})",
        "checkout_tip": "Checkout - pour passer à la caisse sur votre commande en cours",
        "checkout_zero": "Mon panier",
        "content_one": "Votre panier contient le produit {{count}}.",
        "content_other": "Vous avez {{count}} {{units}} dans votre panier.",
        "empty_booked_desc": "Les articles nouvellement ajoutés apparaîtront ici pour être examinés. Une fois qu'ils sont prêts, c'est également ici que vous finalisez la commande et que vous réservez ces articles.",
        "empty_booked_title": "Vous n'avez pas (encore) d'articles nouvellement sélectionnés",
        "empty_desc": "Les produits ajoutés à la commande s'affichent ici pour être examinés. Une fois qu'ils sont prêts, c'est également ici que vous finalisez votre commande et que vous réservez ces articles.",
        "empty_title": "Ajouter des produits",
        "error_api": "Une erreur s'est produite dans ton panier.",
        "error_help": {
            "api": "Recharge la page et/ou supprime le produit qui pose problème. Contacte l'assistance si le problème persiste.",
            "less_available": "Mettre à jour le panier abaissera le montant du produit jusqu'au montant disponible.",
            "minimum_quantity": "La mise à jour du panier augmentera le montant du produit jusqu'à la quantité minimale de la commande.",
            "price_up": "Mettre à jour le panier augmentera le prix de l'article du panier.",
            "unavailable": "Mettre à jour le panier supprimera ce produit du panier"
        },
        "error_less_available": "Moins disponible",
        "error_minimum_quantity": "La quantité minimale de commande s'applique",
        "error_ok": "Produits prêts à l'emploi",
        "error_one": "Ce produit requiert ton attention",
        "error_other": "Ces produits requièrent ton attention",
        "error_price_up": "Le prix du produit a été augmenté",
        "error_unavailable": "Indisponible",
        "issues": "Mises à jour du panier d'achat",
        "issues_action": "Pour pouvoir valider votre panier, vous devez répondre aux questions suivantes sur les produits.",
        "issues_none": "Ton panier est prêt",
        "less_available_action": "Ajustez le montant du panier en conséquence ou retirez le produit du panier pour poursuivre votre commande.",
        "less_available_one": "Seul le site {{count}} {{units}} est disponible pour le moment.",
        "less_available_other": "Seuls {{count}} {{units}} sont disponibles pour le moment.",
        "min_quantity": "La quantité minimale de commande pour ce produit est de {{count}} {{units}} .",
        "min_quantity_action": "Ajustez le montant du panier en conséquence ou retirez le produit du panier pour poursuivre votre commande.",
        "min_quantity_confirm": "Confirmer le montant minimum de la commande : {{count}} {{units}}",
        "minus_all": "Retirez {{product}} de votre panier.",
        "minus_one": "Retirez {{count}} {{units}} de votre panier.",
        "minus_other": "Retirez {{count}} {{units}} de votre panier.",
        "new": "Panier vide",
        "new_key": "Je suis un traducteur",
        "new_quantity_confirmed": "Nouvelle quantité confirmée.",
        "panel": {
            "header": {
                "cart": "Votre panier ({{id}})",
                "cart_new": "Your Cart"
            }
        },
        "plus_all": "Ajouter {{product}} à votre panier",
        "plus_one": "Ajouter {{count}} {{units}} au panier",
        "plus_other": "Ajouter {{count}} {{units}} au panier",
        "price_changed": "Le prix de ce produit est passé de {{old_price}} {{currency}} à {{new_price}} {{currency}} .",
        "price_changed_action": "Confirmez le prix modifié ou retirez le produit du panier pour poursuivre votre commande.",
        "price_confirm": "Confirme les nouveaux prix.",
        "price_confirmed": "Nouveau prix confirmé.",
        "price_up_agree_help_one": "Met à jour ton panier avec le prix augmenté",
        "price_up_agree_help_other": "Met à jour ton panier avec les prix augmentés",
        "price_up_agree_one": "J'accepte le prix majoré",
        "price_up_agree_other": "J'accepte l'augmentation des prix",
        "product_amount": "Votre panier contient maintenant {{count}} {{units}} de {{product}}",
        "product_removed": "Le produit {{product}} a été retiré de votre panier",
        "rounded_one": "Le nombre est arrondi à {{count}} cas ({{bottles}} bouteilles).",
        "rounded_other": "Le nombre est arrondi à {{count}} caisses ({{bottles}} bouteilles).",
        "soi": {
            "booked_title": "Réservé à toi",
            "cart_title": "Le contenu de ton panier ({{count}} produits)"
        },
        "stock_one": "Nous pouvons te fournir {{count}} {{units}} ",
        "stock_other": "Nous pouvons te fournir {{count}} {{units}} ",
        "switched": "Vous venez de passer au panier {{cart}}",
        "switched_new": "Vous venez de changer de panier",
        "total_bottles": "Total des bouteilles",
        "total_cases": "Total des cas",
        "total_price": "Prix totaux",
        "unavailable": "Malheureusement, ce produit n'est plus disponible.",
        "unavailable_action": "Retirez le produit de votre panier pour poursuivre la commande.",
        "yours": "Your Cart"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Articles arrivés",
                "stock": "Du stock",
                "tbo": "À commander"
            }
        },
        "cart": "Chariot",
        "comment_delete_tip": "Supprimer le commentaire",
        "comment_store": "Sauvegarder le commentaire",
        "comment_stored": "Commentaire stocké",
        "comment_title": "Ajouter un commentaire pour ce produit",
        "delivery": {
            "asap": "Dès que possible",
            "date": "À une date précise"
        },
        "status": {
            "book": "Réserver les produits ; finaliser la commande ultérieurement",
            "book_help": "Les produits sont réservés pour vous, vous pouvez ajouter d'autres produits plus tard et finaliser votre commande à ce moment-là.",
            "confirm_cart_update_help": "Toutes les mises à jour du panier seront appliquées automatiquement",
            "finalize": "Finaliser la commande",
            "finalize_help": "Votre commande devient définitive et nous vérifierons votre commande ; vous pouvez d'ores et déjà télécharger la facture proforma.",
            "step0_title": "Ton panier",
            "step1_button": "Finaliser le panier",
            "step1_button_fix": "Mettre à jour le panier",
            "step1_title": "Vérifier le panier",
            "step2_button": "Passer commande",
            "step2_button_book": "Produits du livre",
            "step2_title": "Checkout Cart"
        },
        "total": "Total",
        "vat": "TVA",
        "vouchers": {
            "label": "Vouchers"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "Ce compte de démonstration vous permet de consulter les stocks, de voir les prix et de télécharger les listes de prix. Vous pouvez mettre votre compte à niveau en contactant le responsable des ventes. Avec votre propre compte, vous pourrez passer des commandes et marquer des articles comme favoris.",
                "context_2": "Nous ne traitons pas les ordres passés sur ce compte de démonstration. Vous ne serez donc pas facturé. N'hésitez pas à expérimenter ! N'hésitez pas à contacter votre responsable des ventes si vous avez des questions. Vous trouverez ses coordonnées dans le coin inférieur gauche.",
                "title": "Bienvenue sur notre portail, c'est une bonne chose que vous vous y soyez arrêté !"
            },
            "latest_orders": {
                "activate_order_tip": "Activer la commande",
                "active_order": "Commande en cours",
                "stock_complete": "Tous les articles sont en stock"
            },
            "tips": {
                "title": "Conseils et astuces"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "Très bien !",
            "checkout_context_1": "Vous avez trouvé ! La page avec les produits que vous voulez commander et où vous pouvez confirmer votre commande n'est pas fonctionnelle avec un compte démo. Vous avez besoin d'un compte complet pour cela. Pour ce faire, vous avez besoin d'un compte réel, que votre salesmanager peut vous fournir.",
            "checkout_context_2": "N'hésitez pas à nous faire part de vos commentaires sur ce portail. Nous pourrons ainsi l'améliorer pour vous (et pour nous). Si vous avez des questions, n'hésitez pas à contacter votre responsable des ventes. Vous trouverez ses coordonnées dans le coin inférieur gauche.",
            "checkout_title": "Page de paiement"
        },
        "favorites_disabled": "Malheureusement, il n'est pas possible d'enregistrer des favoris sur votre compte de démonstration. Veuillez contacter votre responsable des ventes si vous avez des questions. Vous trouverez ses coordonnées dans le coin inférieur gauche."
    },
    "email_address": "Adresse électronique",
    "excise": {
        "excluding": "excl. {{amount}} excise",
        "including": "{{amount}} accise incluse"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Format de l'alcool",
            "alcohol_format_help": "Comment veux-tu présenter le pourcentage d'alcool ?",
            "cancel_download": "Annuler",
            "download": "Télécharger la liste des prix",
            "download_tip": "Exporter la liste des prix au format Excel (.xlsx)",
            "download_tip_busy": "La liste des prix est en cours de composition pour vous...",
            "filename": "liste de prix export",
            "products_selected": "Produits : {{count}}/{{total}}",
            "select_all": "Sélectionner tous les produits...",
            "volume_format": "Format du volume",
            "volume_format_help": "Comment veux-tu formater le volume de la bouteille ?"
        },
        "reset_one": "Réinitialiser le filtre",
        "reset_other": "Réinitialiser les filtres",
        "selected_one": "{{count}} sélectionné",
        "selected_other": "{{count}} sélectionné",
        "title": {
            "alcohol": "Pourcentage d'alcool",
            "availability": "Disponibilité",
            "offertype": "Type d'offre",
            "packaging": "Emballage",
            "refill": "Rechargeable",
            "search": "Recherche",
            "volume": "Volume"
        },
        "types": {
            "availability": {
                "eta": "Arrivée prévue : {{period}}",
                "purchase": "Arrivée",
                "stock": "En stock",
                "tbo": "A commander"
            },
            "offertype": {
                "FAVORITES": "Favoris",
                "NEW_ARRIVALS": "Nouveaux arrivages",
                "SPECIALS": "Offres spéciales"
            },
            "products": "Produits",
            "refill": {
                "nonref": "Non rechargeable",
                "ref": "Rechargeable"
            }
        }
    },
    "help": {
        "explain": "Expliquer cette page"
    },
    "loading": "Chargement",
    "location": "Localisation",
    "messroom": {
        "description": "Il s'agit d'une configuration unique visant à optimiser l'expérience d'achat sur notre portail, tout en vous offrant les informations les plus récentes en matière de prix et de disponibilité. Veuillez patienter jusqu'à ce que nous préparions votre compte sur le portail. Nous vous remercions de votre patience !",
        "step0": "Préparation du compte",
        "step1": "Mise à jour des disponibilités",
        "step2": "Générer des prix",
        "steps_completed": "C'est fait ! Vous allez maintenant être redirigé vers le portail...",
        "steps_processing": "Veuillez patienter pendant que nous préparons votre compte portail...",
        "team": "L'équipe {{team}}",
        "title": "Mise en place du portail en une seule fois"
    },
    "my_portal": {
        "news": "Actualités",
        "privacy": "Vie privée",
        "profile": "Mon profil",
        "promotions": "Promotions",
        "security": {
            "label": "Sécurité",
            "notifications": {
                "current_password_invalid": "Ton mot de passe actuel n'est pas valide",
                "new_password_invalid": "Ton nouveau mot de passe n'est pas valide",
                "password_updated": "Ton mot de passe a été mis à jour ; connecte-toi à nouveau."
            },
            "password": {
                "current": "Mot de passe actuel",
                "current_help": "Votre mot de passe actuel est nécessaire pour le mettre à jour.",
                "new": "Nouveau mot de passe",
                "new_help": "Le mot de passe doit comporter au moins 8 caractères",
                "repeat": "Répéter le mot de passe",
                "repeat_help": "Après avoir modifié votre mot de passe, il vous sera demandé de vous connecter à nouveau."
            },
            "save_button": "Modifier le mot de passe"
        },
        "settings": {
            "label": "Paramètres",
            "price_quantity": "Prix et quantités",
            "price_quantity_help": "Utiliser les prix des bouteilles ou des caisses dans l'ensemble du portail",
            "save": "Sauvegarder les paramètres",
            "saved_profile": "Votre profil a été sauvegardé",
            "saved_profile_error": "Ton profil n'a pas pu être mis à jour, essaie à nouveau plus tard.",
            "saved_settings": "Vos paramètres ont été sauvegardés"
        }
    },
    "no": "Non",
    "notifications": {
        "cart_api_error": "Désolé, il y a eu un problème inattendu lors de la mise à jour de ton panier. Essaie de recharger le portail. Nous sommes déjà dessus, mais n'hésite pas à contacter l'assistance si le problème persiste.",
        "comment_deleted": "Commentaire supprimé",
        "comment_stored": "Commentaire stocké",
        "invite_failure": "Ton code d'invitation a expiré ou n'est plus valide.",
        "messroom_ready": "Votre portail est prêt à être utilisé !",
        "news": {
            "title": "Mise à jour des nouvelles !"
        },
        "order_booked": "Tes produits en commande ({{id}}) sont réservés par nous !",
        "order_confirmed": "Votre commande ({{id}}) est confirmée par nos soins !",
        "order_failure": "Un problème s'est produit lors du chargement de la commande {{order}}; Réessaie plus tard.",
        "order_issue": "Il y a un problème avec un ou plusieurs articles de votre commande ; veuillez vérifier les avertissements relatifs à la commande.",
        "order_repeated": "Les lignes de la commande {{order}} ont été copiées dans ton panier.",
        "promotion_redeemed": "Félicitations ! Tu viens d'échanger ta promotion !",
        "removed_product": "Le produit {{product}} a été retiré de votre panier",
        "session_expired": "Votre session sur le portail a expiré ; vous devez malheureusement vous connecter à nouveau."
    },
    "offer": {
        "arriving_soon": "Bientôt disponible",
        "best_deal": "Palette Deal",
        "delivery_week_one": "{{count}} semaine",
        "delivery_week_other": "{{count}} semaines",
        "download_offer": "Offre de téléchargement",
        "expired": {
            "description": "Malheureusement, l'offre personnelle que tu visualises n'est plus valable. Vérifie notre page de stock pour d'autres offres.",
            "title": "Offre expirée"
        },
        "latest_orders": "Vos dernières commandes",
        "latest_orders_empty": "Vos commandes les plus récentes apparaissent ici",
        "loading_products": "Récupération de produits...",
        "my_favourites": "Mes favoris",
        "my_favourites_add": "Ajouter à mes favoris",
        "my_favourites_help": "Marquez les articles comme favoris à l'aide de l'icône en forme de cœur. Ces produits apparaîtront ici et dans le menu \"Mes favoris\".",
        "my_favourites_remove": "Supprimer des favoris",
        "my_favourites_tip": "Mes favoris - une sélection de vos produits préférés",
        "name": "Promotion",
        "new_arrivals": "Nouveaux arrivages",
        "new_arrivals_tip": "Nouveaux arrivages - nos produits les plus récents ; ils viennent d'arriver ou arriveront bientôt sur le sol.",
        "newest_arrivals": "Nos nouveaux arrivants",
        "no_personal": "Pas d'offres personnelles pour le moment",
        "personal": "Vos offres personnelles",
        "personal_not_found": "Malheureusement, cette offre spéciale n'est plus disponible ; as-tu vu nos autres offres ?",
        "personal_valid": "Une offre personnelle pour toi : '{{text}}'\n",
        "popup": {
            "continue": "Vers le tableau de bord",
            "show_offer": "Voir l'offre"
        },
        "show_all": "Afficher tout",
        "special": "Offres spéciales",
        "special_count": "Offres spéciales",
        "special_tip": "Offres spéciales - produits bénéficiant d'un prix spécial, valable pour une durée limitée.",
        "valid_until": "Valable jusqu'au {{date}}",
        "view_offer": "Voir l'offre"
    },
    "order": {
        "agree_t1": "Possède des produits avec le statut douanier T1",
        "agree_tos": "Je suis d'accord avec les <a href={{link}} target=\"_blank\">termes et conditions.</a>",
        "completion": "Achèvement de la commande",
        "confirmed": {
            "description": "Maintenant, c'est notre tour ; nous ferons en sorte que la marchandise vienne à toi. En attendant, n'hésite pas à garder un œil sur notre stock pour d'autres offres exceptionnelles. N'hésite pas à nous contacter, si tu as des questions concernant ta commande.",
            "subtitle": "Votre commande a été passée avec succès",
            "title": "Félicitations !"
        },
        "delivery": "Préférence de livraison",
        "delivery_date": "Date de livraison",
        "details": {
            "actions": {
                "download_delivery_note": "Télécharger le bulletin de livraison",
                "download_invoice": "Télécharger la facture",
                "download_order_note": "Télécharger la note de commande",
                "download_proforma": "Télécharger le formulaire",
                "repeat_order": "Dans le panier",
                "repeat_order_tip": "Copie le contenu de cette commande dans ton panier. Cela ne peut se faire que pour les produits et les quantités qui sont actuellement en stock."
            },
            "additional": {
                "description": "Description",
                "price_unit": "Prix par unité",
                "quantity": "Quantité",
                "type": "Type"
            },
            "additionals": "Articles supplémentaires et réductions",
            "booked": "Réservé",
            "booked_context": "Ces articles sont confirmés et réservés par vous et donc réservés par nous.",
            "cart": "Chariot",
            "cases": "Caisses",
            "comment": "Comment",
            "created_by": "Créé par",
            "credit": {
                "credited_from": "Crédité de",
                "label": "Postes de crédit"
            },
            "date": "Date",
            "empty": "Votre commande n'a pas encore de produits",
            "empty_context": "Ajoutez des produits à votre panier à partir de notre aperçu des stocks.",
            "invoice_date": "Date de la facture",
            "invoice_number": "Numéro de facture",
            "items": {
                "arriving": "Bientôt disponible",
                "basket_unconfirmed": "Articles du panier (non confirmés)",
                "basket_unconfirmed_help": "Ces articles doivent encore être confirmés par vos soins avant que nous puissions les réserver. Confirmez votre commande pour continuer.",
                "stock": "Du stock",
                "tbo": "A commander"
            },
            "order_number": "Numéro de commande",
            "order_status": "Statut de la commande",
            "price": "Prix",
            "product": "Produit",
            "quantity": "Quantité",
            "specs": "Spécifications",
            "status": "Statut",
            "subtotal": "Sous-total",
            "total_value": "Valeur totale",
            "vat": "TVA"
        },
        "empty_call_to_action": "Parcourir le stock",
        "loading_orders": "Chargement des commandes...",
        "new": "Nouveau",
        "tos": "Conditions générales d'utilisation"
    },
    "orders": {
        "invoice_date": "Facturé le",
        "invoice_number": "Numéro de la facture",
        "no_orders": "Vous n'avez pas encore de commande",
        "portal_status": {
            "Cancelled": "Annulé",
            "Confirmed": "Confirmé",
            "Finalized": "Finalisé",
            "Invoiced": "Facturé",
            "New": "Nouveau",
            "Pending": "En attente"
        },
        "status": "Statut de la commande",
        "status_active": "Actif",
        "status_open": "Ouvrir"
    },
    "page": {
        "browse_stock_tip": "Parcourir le stock - aperçu de tous nos produits",
        "dashboard": "Tableau de bord",
        "dashboard_tip": "Tableau de bord - une vue d'ensemble de nos meilleures affaires et offres",
        "latest_news": "Dernières nouvelles",
        "my_orders": "Mes commandes",
        "my_orders_tip": "Mes commandes - une vue d'ensemble de toutes vos commandes",
        "my_portal": "Mon portail",
        "my_portal_tip": "Mon portail - profil, nouvelles et promotions",
        "my_portal_tip_promotion": "Mon portail - Une promotion est en cours !",
        "subtitle": {
            "login": "Se connecter pour continuer...",
            "privacy": "Nous vendons des spiritueux, pas vos données !",
            "redeem": "Inscrivez-vous avec votre code unique...",
            "registration": "Parlez-nous de votre entreprise...",
            "registration_completed": "Vous pouvez fermer cette fenêtre maintenant..."
        },
        "title": {
            "checkout": "Checkout",
            "checkout-0": "Acheter des produits",
            "checkout-1": "Vérifier le panier",
            "checkout-2": "Finaliser le panier",
            "login": "Bienvenue sur {{vendor}}!",
            "login_slug": "Connexion",
            "offer": {
                "my_favourites": "Mes favoris",
                "new_arrivals": "Nouveaux arrivages",
                "personal_offer": "Offre personnelle - jusqu'à {{date}}",
                "personal_offer_invalid": "L'offre personnelle a expiré",
                "special_offers": "Offres spéciales"
            },
            "order": "Commande {{id}}",
            "privacy": "Notre politique de confidentialité",
            "redeem": "Ravi de vous voir !",
            "registration": "Inscription",
            "registration_completed": "Merci pour votre candidature !"
        }
    },
    "product": {
        "alcohol_percentage": "Pourcentage d'alcool",
        "best_before": "Meilleur avant",
        "bottle_gtin": "Bouteille GTIN",
        "bottle_volume": "Bottle Volume",
        "bottles": "Bouteilles",
        "bottles_per_case": "Bouteilles par carton",
        "cases": "Caisses",
        "category": "Catégorie",
        "country_of_origin": "Pays d'origine",
        "cpp_cpl_tip": "Disposition des palettes telle qu'elle est fournie par notre entrepôt.",
        "customs_status": "Statut douanier",
        "features": "Caractéristiques",
        "minimum_order_quantity": "Quantité minimale de commande",
        "refill_status": "Rechargeable",
        "stock_origin": "Ce produit est originaire de {{country}}",
        "stock_origin_count_one": "Nous pouvons te fournir {{count}} {{units}} à partir de cette origine",
        "stock_origin_count_other": "Nous pouvons te fournir {{count}} {{units}} à partir de cette origine",
        "weight_tip": "Poids de l'étui"
    },
    "profile": {
        "company": "Votre entreprise",
        "email_support": "Adresse électronique de l'assistance",
        "family_name": "Nom de famille",
        "first_name": "Prénom",
        "mobile_phone_number": "Numéro de téléphone mobile",
        "new_password": "Choisir un mot de passe",
        "new_password_help": "Le nouveau mot de passe à définir",
        "new_password_placeholder": "Choisir un mot de passe",
        "phone_email": "Numéro de téléphone ou adresse électronique",
        "phone_number": "Numéro de téléphone",
        "portal_welcome_desc": "Veuillez vous connecter pour continuer...",
        "portal_welcome_title": "Bienvenue sur notre portail !",
        "register": "Enregistrement d'un compte gratuit",
        "removal": {
            "ack": "Votre demande a été envoyée !",
            "ack_description_1": "Votre demande a été envoyée. Nous reviendrons vers vous dès que possible, mais au moins dans un délai de 4 semaines.",
            "ack_description_2": "Vous allez nous manquer, alors si vous avez accidentellement déposé cette demande, veuillez nous contacter à l'adresse {{email_vendor}} ou contacter votre responsable du support personnel, {{manager}} à l'adresse {{email}} ou {{phone}}.",
            "cancel": "Annuler la demande",
            "close_window": "Dialogue fermé",
            "confirm": "Confirmer la demande"
        },
        "removal_description_1": "Lorsque vous procédez, nous déterminons la suite à donner à votre demande de suppression dans les plus brefs délais, et au moins dans les quatre semaines qui suivent. Nous vous informerons des mesures que nous prendrons pour répondre à votre demande. Si nous ne donnons pas suite à votre demande, vous pouvez déposer une plainte auprès de l'Autorité des données personnelles.",
        "removal_description_2": "Avant de confirmer votre demande de suppression, nous aimerions vous demander qui nous pouvons contacter pour votre entreprise à votre place. Pourriez-vous nous laisser un nom et un numéro de téléphone ou une adresse électronique ? Ne le faites que si vous avez l'autorisation de la nouvelle personne de contact. Il n'est pas obligatoire de laisser ces coordonnées !",
        "removal_notice": "Veuillez lire attentivement ce qui suit !",
        "removal_questions": "Pour de plus amples informations, questions ou remarques, veuillez nous envoyer un courrier à l'adresse {{email_vendor}} ou contacter votre gestionnaire de soutien personnel, {{manager}}, à l'adresse {{email}} ou {{phone}}.",
        "removal_title": "Vous nous manquerez !",
        "replacement": {
            "contact": "Contactinfo",
            "name": "Personne de contact"
        },
        "request_password": "Mot de passe oublié ?",
        "request_removal": "Demande de retrait",
        "save_button": "Mise à jour du profil"
    },
    "promotions": {
        "active": "Actif entre : {{date}}",
        "active_grace": "La promotion peut être utilisée jusqu'au : {{date}}",
        "call_to_action": "Commandez maintenant !",
        "confirmed": {
            "description": "Maintenant, c'est notre tour ; nous ferons en sorte que la marchandise vienne à toi. En attendant, n'hésite pas à garder un œil sur notre stock pour d'autres offres exceptionnelles. N'hésite pas à nous contacter, si tu as des questions concernant ta commande.",
            "subtitle": "Ta promotion a été échangée !"
        },
        "description": "Nous avons encore une autre grande promotion pour toi ! Deviens un vrai barista en économisant des grains de café à chaque fois que tu dépenses {{token_value}}. Il existe plusieurs machines à café que tu peux gagner.",
        "details": "Cliquez pour voir les détails de la promotion",
        "eligible_multiple": "Un haricot actif représente {{token_value}} de la valeur de la commande client qui a été confirmée ou facturée par ton directeur commercial. Tu as collecté {{turnover_draft}} de la valeur des commandes clients éligibles, dont {{turnover_redeemable}} est déjà confirmée ou facturée.",
        "gifts": {
            "available": "Cadeaux disponibles",
            "choose": "Votre offre",
            "choose_help": "Vous pouvez choisir une offre après avoir été éligible à la promotion.",
            "disabled": "Vous pouvez choisir un cadeau",
            "locked": "Débloquez votre cadeau en ajoutant {{amount}} à votre commande",
            "locked_placeholder": "Débloquez d'abord votre don...",
            "unlocked": "Félicitations ! Vous avez débloqué un cadeau gratuit !",
            "unlocked_choice_made": "{{choice}} - Bon choix !",
            "unlocked_choose": "Sélectionnez votre cadeau préféré ci-dessous...",
            "unlocked_placeholder": "Sélectionnez votre cadeau gratuit...",
            "unlocked_redirect": "Félicitations ! Vous avez débloqué un cadeau gratuit ! Consultez la section <a href={{link}}>promotions</a> pour plus d'informations sur les différents cadeaux..."
        },
        "giveaway": "Commande {{spent_next}} {{currency}} pour gagner un grain de café !",
        "giveaway_encourage": "Commande {{spent_next}} {{currency}} plus et réclame un grain de café !",
        "incentive_hurry_one": "Il ne reste plus que {{count}} jour !",
        "incentive_hurry_other": "Il ne reste plus que {{count}} jours !",
        "incentive_progress": "Commande {{amount}} plus pour un grain de café !",
        "label": {
            "dialog_subtitle": "Tu as toujours voulu être un barista ? C'est l'occasion ou jamais ! Gagne un grain de café pour chaque {{token_value}} que tu commandes et choisis l'une des trois fabuleuses machines à café ! Et pour couronner le tout, nous t'offrons pas moins de 6 kg de grains de café Arabica bio ! Conserve tes grains et prépare un délicieux Americano, Latte ou Expresso !",
            "promotion": "Promotion",
            "redeemable_tokens": "Haricots ramassés",
            "show_promotion": "Promotion du salon"
        },
        "order_reminder": "Tu es presque prêt à devenir un barista ! Une fois que tu auras atteint {{token_value}}, tu pourras échanger ta promotion.",
        "order_reminder_eligible": "Tu n'es plus qu'à un pas de devenir un barista ! Nous sommes en train d'examiner ta commande et tu recevras un courriel de confirmation de notre part dans les 24 heures. Une fois que tu auras reçu ce courriel, tu pourras échanger tes grains de café. Merci pour ta patience !",
        "redeem": "Racheter",
        "terms": {
            "conditions": "Conditions générales d'utilisation",
            "period": "Période de promotion : {{period}}",
            "terms_0": "La promotion est valable pour les commandes passées via notre portail ; nous comptons toutes tes commandes finalisées pendant cette période.",
            "terms_1": "Une fois que tu as commandé et que tu es éligible pour recevoir un grain de café, tu peux immédiatement commander une machine à expresso via le portail, que nous ajouterons à ta commande.",
            "terms_2": "Les machines à expresso proposées sont soumises à la disponibilité des stocks. Si les modèles sélectionnés ne sont pas disponibles, nous choisirons un modèle comparable/équivalent en concertation avec toi.",
            "terms_3": "Nous ne sommes pas responsables des informations fournies par le fabricant sur son site Internet. Ces informations sont données à titre purement indicatif.",
            "terms_4": "Le montant total, est le montant de la valeur de la marchandise commandée et payée par facture. Les coûts supplémentaires facturés ne sont pas pris en compte dans la promotion (expédition / camionnage / palettes).",
            "terms_5": "La garantie du produit n'est applicable qu'aux Pays-Bas.",
            "terms_6": "Une machine à expresso ne peut pas être échangée contre une remise en espèces.",
            "terms_7": "Cette promotion a été élaborée pour toi avec soin, sous réserve toutefois d'erreurs typographiques."
        }
    },
    "registration": {
        "address": "Adresse",
        "address_placeholder": "Adresse de l'entreprise",
        "agree": "Oui, je suis d'accord avec les <a href={{link}} target=\"_blank\">termes et conditions</a>",
        "back_login": "Retour au login",
        "city": "Ville",
        "city_placeholder": "Ville de l'entreprise",
        "company_details": "Coordonnées de l'entreprise",
        "company_name": "Nom de l'entreprise",
        "company_name_placeholder": "Nom de l'entreprise",
        "consent": "Courriels commerciaux et offres de {{vendor}}",
        "country": "Pays",
        "country_help": "Le pays dans lequel vous exercez votre activité",
        "email_address": "Your Email Address",
        "email_address_help": "L'adresse électronique à laquelle nous pouvons vous joindre",
        "explanation": "{{vendor}} fournit des clients Business to Business dans toute l'Europe et au-delà. En tant que client de {{vendor}}, vous bénéficiez d'un accès illimité à notre portail de vente. Vous pouvez profiter des avantages d'un accès 24 heures sur 24, 7 jours sur 7, à la vaste gamme de produits que nous proposons, quand vous le souhaitez. Passer une commande est facile : il suffit de naviguer, de choisir, de cliquer et le tour est joué !",
        "family_name": "Nom de famille",
        "family_name_placeholder": "Votre nom de famille",
        "first_name": "Prénom",
        "first_name_placeholder": "Votre prénom",
        "import_goods_temp_license": "J'importe des marchandises avec une licence temporaire",
        "invite": "Invites",
        "message": "Questions?",
        "message_placeholder": "Vous avez des questions à nous poser ? N'hésitez pas à les poser...",
        "name": "Votre nom",
        "position": "Le rôle de votre entreprise",
        "position_placeholder": "Le rôle de votre entreprise",
        "postal_code": "Code postal",
        "postal_code_placeholder": "Code postal de l'entreprise",
        "redeem": {
            "code": "Invite code",
            "code_help": "Your unique code to access our portal",
            "partnership": {
                "footer": "Avec amour pour notre planète",
                "header": "{{vendor}} est un fier partenaire de {{partner}}."
            },
            "step0_desc": "Tu es aussi enthousiaste que nous dans le domaine des spiritueux ? Connecte-toi avec ton code personnel pour réclamer ton cadeau et avoir accès à notre assortiment étendu, varié et à prix serrés.",
            "step0_slug": "C'est parti !",
            "step1_desc": "Remplissez l'adresse e-mail que vous souhaitez utiliser, choisissez un mot de passe et c'est parti !",
            "step1_slug": "À propos de vous",
            "step2_desc": "Les informations suivantes sont-elles correctes ? Cliquez alors sur le bouton \"Register &amp; Login\" et vous serez automatiquement connecté au portail.",
            "step2_slug": "Révision de l'enregistrement",
            "step3_slug": "Enregistrement &amp; connexion"
        },
        "review_request": "Veuillez vérifier toutes les informations que vous avez fournies. Si tout est correct, vous pouvez soumettre votre inscription et nous vous contacterons dans les plus brefs délais.",
        "signup": "Devenir client",
        "step0_desc": "{{vendor}} fournit aux clients Business to Business. Pour quelle entreprise souhaitez-vous demander un compte ?",
        "step0_slug": "Votre entreprise",
        "step1_desc": "Veuillez indiquer l'adresse de votre entreprise.",
        "step1_slug": "Adresse de l'entreprise",
        "step2_desc": "Comment pouvons-nous vous contacter ?",
        "step2_slug": "Coordonnées",
        "step3_desc": "Utilisez-vous les installations d'un entrepôt sous douane dans l'UE ?",
        "step3_desc_vat": "Veuillez indiquer votre numéro de TVA et vos préférences en matière d'entreposage.",
        "step3_slug": "Détails logistiques",
        "step4_desc": "Veuillez prendre connaissance des informations que vous vous apprêtez à nous envoyer pour votre demande d'inscription. Vous pouvez utiliser le champ de questions ci-dessous, au cas où vous auriez des questions à nous poser.",
        "step4_slug": "Révision de l'enregistrement",
        "step5_desc": "Nous vérifierons les coordonnées de l'entreprise que vous avez fournies. Ce processus peut prendre quelques jours. Si nous avons besoin d'informations supplémentaires, nous vous contacterons personnellement. Après confirmation, vous recevrez un courriel contenant un accusé de réception de votre inscription.",
        "step5_footer": "Nous nous réjouissons de recevoir bientôt votre première commande en ligne !",
        "step5_incentive": "Saviez-vous que nous avons notre propre <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">chaîne Youtube</a>?",
        "step5_notify": "Merci pour votre candidature ! Nous traiterons votre candidature dans les jours ouvrables suivants.",
        "step5_slug": "Soumettre une demande",
        "step5_title": "Que se passe-t-il ensuite ?",
        "submit": "Soumettre l'inscription",
        "telephone": "Votre numéro de téléphone",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "Conditions générales d'utilisation",
        "vat_number": "Numéro de TVA",
        "vat_number_help": "Un numéro de TVA est requis dans les pays de l'UE",
        "vat_number_placeholder": "Numéro de TVA",
        "warehouse": {
            "address": "Adresse de l'entrepôt",
            "address_help": "Adresse de l'entrepôt",
            "address_unloading": "Adresse de déchargement",
            "address_unloading_help": "L'endroit où nous pouvons décharger votre commande",
            "bonded_eu": "Entrepôt sous douane dans l'UE ?",
            "city": "Cité de l'entrepôt",
            "city_unloading": "Ville de déchargement",
            "excise_id": "ID d'accise de l'entrepôt",
            "location_id": "ID de l'emplacement de l'entrepôt",
            "name": "Nom de l'entrepôt",
            "name_unloading": "Déchargement",
            "options": {
                "bonded": "Je dispose de mon propre entrepôt sous douane",
                "inc_vat": "J'achète TVA et accises incluses aux Pays-Bas",
                "other": "Autres",
                "temp_license": "J'importe des marchandises avec une licence temporaire",
                "third_party": "J'utilise un entrepôt tiers/un partenaire d'entrepôt"
            },
            "postal_code": "Code postal de l'entrepôt",
            "postal_code_unloading": "Code postal de déchargement",
            "select": "Sélectionner l'entrepôt",
            "shipping": {
                "free_shipping": "C'est très bien ! Nous livrerons votre commande gratuitement.",
                "incentive": "Ajouter <b>{{amount}}</b> à votre commande pour bénéficier de la livraison gratuite. Pour les commandes inférieures à <b>{{threshold}}</b>les frais de transport supplémentaires seront ajoutés à votre facture proforma pour confirmation."
            },
            "warehousing": "Entreposage"
        },
        "website": "Site web de l'entreprise",
        "website_placeholder": "votre-entreprise.com"
    },
    "search": {
        "categories": {
            "category": "catégorie",
            "search": "nom du produit"
        },
        "exact": "Recherche par nom de produit ou GTIN en utilisant le terme \"{{term}}\".",
        "placeholder": {
            "general": "Que recherchez-vous ?",
            "raw": "Recherche par nom de produit ou GTIN",
            "raw_term": "Recherche par nom de produit ou GTIN en utilisant le terme \"{{term}}\".",
            "suggestion": "Recherche par {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> pour une recherche de termes exacts"
        }
    },
    "select": "Sélectionner",
    "spotlight": {
        "countdown": {
            "still_unsold": "Toujours invendus",
            "valid_for": "L'offre est toujours valable :"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Dommage, ce code n'est pas correct ; réessayez.",
                "length": "Le code de remboursement est composé de 6 caractères",
                "unknown": "Un problème s'est produit lors de la vérification de votre code d'échange ; veuillez réessayer plus tard.",
                "used": "Ce code d'invitation a déjà été utilisé pour créer un compte."
            }
        }
    },
    "view": {
        "grid": "Grille",
        "grid_switch": "Passer à l'affichage en grille",
        "list": "Liste",
        "list_switch": "Passer à l'affichage en liste"
    },
    "yes": "Oui"
}