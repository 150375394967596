import m from 'mithril'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {PanelMenu} from '@bitstillery/common/components'
import {Button} from '@bitstillery/common/components'
import {$t, api} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {StatisticsForFilterResponse} from '@/factserver_api/fact2server_api'
import {filters} from '@/components/pages/offers/offer_list'

export class PanelMenuPortal extends PanelMenu {

    async oninit() {
        const {result, status_code} = await api.post<StatisticsForFilterResponse>('portal/filter/statistics', {
            offer_item_type: 'NEW_ARRIVALS',
            search_terms: [],
        }, true)
        if (status_code > 299) {
            return
        }

        $s.offers.special = result.offer_item_statistics ? result.offer_item_statistics[0].count : 0
        $s.offers.new_arrivals = result.offer_item_statistics ? result.offer_item_statistics[1].count : 0
    }

    link_active(section: string, modifier: string) {
        const pathname = m.parsePathname(m.route.get())
        if (section === 'offers' && pathname.path.startsWith('/offers')) {
            if (filters.offertype.loading) return false
            return modifier === filters.offertype.selection
        } else if (section === 'orders') {
            if (modifier === 'checkout') {
                return pathname.path.startsWith(`/orders/${$s.cart.artkey}/checkout`)
            } else if (modifier === 'orders') {
                return pathname.path.startsWith('/orders') && !pathname.path.startsWith(`/orders/${$s.cart.artkey}/checkout`)
            }
        }
    }

    slot_menu() {
        const btn = {align: 'left', type: 'default', variant: 'menu'}
        return <div className="menu">
            <Button
                {...btn}
                icon='dashboard'
                link='/dashboard'
                text={$t('page.dashboard')}
                tip={$t._('page.dashboard_tip')}
                type="brand"
            />

            <Button
                {...btn}
                active={this.link_active('offers', '')}
                icon='storeSearch'
                link={get_route('/offers')}
                tip={$t._('page.browse_stock_tip')}
                text={$t('page.title.checkout-0')}
                type="brand"
            />
            <Button
                {...btn}
                active={this.link_active('offers', 'SPECIALS')}
                icon="specialOffers"
                link={get_route('/offers', {offertype: 'SPECIALS'})}
                tip={$t._('offer.special_tip')}
                text={$s.offers.special ? $t('offer.special_count', {special: $s.offers.special}) : $t('offer.special')}
                type="brand"
            >
                {$s.offers.special > 0 && (
                    <div className="amount">
                        {$s.offers.special}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={this.link_active('offers', 'NEW_ARRIVALS')}
                align='left'
                icon='newArrivals'
                link={get_route('/offers', {
                    offertype: 'NEW_ARRIVALS',
                    sort_by: 'entry_date',
                    sort_order: 'desc',
                })}
                tip={$t._('offer.new_arrivals_tip')}
                text={$t('offer.new_arrivals')}
                type="brand"
            >
                {$s.offers.new_arrivals > 0 && (
                    <div className="amount">
                        {$s.offers.new_arrivals}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={this.link_active('offers', 'FAVORITES')}
                disabled={$s.identity.demo}
                icon='favorite-o'
                link={get_route('/offers', {offertype: 'FAVORITES'})}
                tip={$t._('offer.my_favourites_tip')}
                text={$t('offer.my_favourites')}
                type="brand"
            />

            <hr/>

            <Button
                {...btn}
                active={this.link_active('orders', 'checkout')}
                className='btn-orders'
                icon='cartRight'
                link={`/orders/${$s.cart.artkey}/checkout?step=1`}
                text={$t('cart.checkout', {count: 0})}
                tip={$t._('cart.checkout_tip')}
                type="brand"
            >
                {$m.cart.cart_units() > 0 && (
                    <div className="amount">
                        {$m.cart.cart_units()}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={this.link_active('orders', 'orders')}
                icon='myOrders'
                link="/orders"
                text={$t('page.my_orders')}
                tip={$t._('page.my_orders_tip')}
                type="brand"
            />

            <Button
                {...btn}
                active={m.route.get().startsWith('/my-portal')}
                className={classes('btn-settings', {
                    'btn-highlight': $s.promotion.active,
                })}
                icon={$s.promotion.active ? $s.promotion.icon : 'settings'}
                link={$s.promotion.active ? '/my-portal?tabId=promotions' : '/my-portal?tabId=profile'}
                text={$s.promotion.active ? $s.promotion.name : $t('page.my_portal')}
                tip={() => {
                    if ($s.promotion.active) return $t('page.my_portal_tip_promotion')
                    return $t('page.my_portal_tip')
                }}
                type="brand"
            >{$s.promotion.active && <div className="highlight-tag">{$t('promotions.label.promotion')}!</div>}</Button>
        </div>
    }
}
