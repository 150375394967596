import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    ButtonDataCard,
    ButtonGroup,
    DataCard,
    PanelContext,
    Rating,
    Spinner,
} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {proforma_or_invoice_renderer, ProformaInvoice} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {outtake_instruction_renderer} from '@bitstillery/common/pdf/outtake_instruction_renderer'
import {download_base64_file} from '@bitstillery/common/lib/utils'
import {format_date, format_money_with_symbol} from '@bitstillery/common/lib/format'
import {$t, api, config, logger, notifier} from '@bitstillery/common/app'

import {Details} from './details'
import {OrderType, order_to_pdf_sales_order} from './proforma'

import {Breadcrumbs} from '@/components/breadcrumbs/breadcrumbs'
import {$m, $s} from '@/app'
import {IncentivePromotion} from '@/components/incentives'
import {OrderRatingResponse, SalesOrderDetailsResponse} from '@/factserver_api/fact2server_api'

export class OrderDetails extends MithrilTsxComponent<any> {

    artkey = parseInt(m.route.param('artkey'))
    data = (() => {

        return proxy({
            feedback: {
                closed: false,
                comment: '',
                loading: true,
                rating: 0,
                submitted: false,
            },
            /** An order from legacy msi/a2bc account */
            legacy_order: false,
            loading: true,
            order: null,
        })
    })()

    confirm_order = m.route.param('confirm_order') !== undefined
    is_promotion = m.route.param('is_promotion') !== undefined

    async oninit() {
        if (!this.artkey) {
            m.route.set('/orders')
            return
        }

        this.data.feedback.loading = false

        Object.assign($s.page, {icon: 'myOrders', title: $t('page.title.order', {id: this.artkey})})
        $s.page.breadcrumbs.splice(0, $s.page.breadcrumbs.length, ['/orders', $t('page.my_orders')], ['', $s.page.title])

    }

    async oncreate() {
        this.data.order = await $m.order.load_full_order(this.artkey)
        this.data.legacy_order = this.data.order.sales_order.account_slug !== process.env.MSI_THEME
        if (!this.data.legacy_order) {
            logger.debug('[orders] retrieving rating details')
            // Rating is not provided for old MSI/A2BC accounts.
            const {result: rating_result} = await api.get<OrderRatingResponse>(`portal/orders/${this.artkey}/rating`)
            Object.assign(this.data.feedback, {
                closed: false,
                comment: rating_result.comment || '',
                rating: rating_result.rating,
                submitted: rating_result.rating > 0,
            })
        }
        this.data.loading = false
        Object.assign($s.context, {
            data: this.data.order,
            icon: 'cart',
            name: 'data_card',
            title: $t('data_card.text'),
        })
    }

    async download_excel_proforma() {
        const {result} = await api.post('sales.export_invoice_to_excel', {
            sales_order_artkey: this.data.order?.sales_order.artkey,
        })

        download_base64_file(result.excel_binary, `Sales order ${this.data.order?.sales_order.reference} ${format_date(new Date)}.xlsx`)
    }

    download_invoice(order: OrderType) {
        const show_quantity_in = $s.cart.show_bottles ? CasesOrBottles.bottles : CasesOrBottles.cases
        const sales_order = order_to_pdf_sales_order(order)
        const pdf = proforma_or_invoice_renderer({
            account: $s.portal.account,
            cases_or_bottles: show_quantity_in,
            decimal_locale: 'en',
            group_similar_items: true,
            proforma_or_invoice: ProformaInvoice.INVOICE,
            sales_order: sales_order,
            show_article_code: false,
            show_cbs_code: true,
            show_bottle_lot: true,
            show_country_of_origin: true,
            show_liters_of_alcohol: false,
        })
        pdf.save(`Invoice - ${order.sales_order.reference} - ${order.invoice_details.supplier.name}.pdf`)
    }

    download_outtake(order: OrderType) {
        const show_quantity_in = $s.cart.show_bottles ? CasesOrBottles.bottles : CasesOrBottles.cases
        const sales_order = order_to_pdf_sales_order(order)
        const pdf = outtake_instruction_renderer({
            account: $s.portal.account,
            cases_or_bottles: show_quantity_in,
            decimal_locale: 'en',
            sales_order: sales_order,
        })
        pdf.save(`Packing list - ${sales_order.reference} - ${sales_order.supplier.name}.pdf`)
    }

    download_proforma(order: OrderType) {
        const show_quantity_in = $s.cart.show_bottles ? CasesOrBottles.bottles : CasesOrBottles.cases

        const sales_order = order_to_pdf_sales_order(order)

        const pdf_file = proforma_or_invoice_renderer({
            account: $s.portal.account,
            cases_or_bottles: show_quantity_in,
            decimal_locale: 'en',
            group_similar_items: true,
            proforma_or_invoice: ProformaInvoice.PROFORMA,
            sales_order: sales_order,
            show_article_code: false,
            show_bottle_lot: true,
            show_cbs_code: true,
            show_country_of_origin: true,
            show_liters_of_alcohol: false,
        })
        pdf_file.save(`Proforma - ${order.sales_order.reference} - ${order.invoice_details.supplier.name}.pdf`)
    }

    value_details(order) {
        const tbo_exclude = ['stock', 'purchase']
        const value_details = [] as any
        const stock_items = order.sales_order_items.filter((i) => i.offer_item_type === 'stock')
        const stock_value = stock_items.map((i) => i.price_per_case * i.number_of_cases).reduce((a, b) => a + b, 0)

        const arriving_items = order.sales_order_items.filter((i) => i.offer_item_type === 'purchase')
        const arriving_value = arriving_items.map((i) => i.price_per_case * i.number_of_cases).reduce((a, b) => a + b, 0)

        if (arriving_items.length) {
            value_details.push({
                label: $t('order.details.items.arriving'),
                value: format_money_with_symbol(arriving_value, order.sales_order.was_sold_in),
            })
        }

        const tbo_items = order.sales_order_tbo_items.filter((i) => !tbo_exclude.includes(i.offer_item_type))
        const tbo_value = tbo_items.map((i) => i.price_per_case * i.number_of_cases).reduce((a, b) => a + b, 0)

        if (tbo_items.length) {
            value_details.push({
                label: $t('order.details.items.tbo'),
                value: format_money_with_symbol(tbo_value, order.sales_order.was_sold_in),
            })
        }

        const credit_items = order.sales_order_credit_items

        if (credit_items.length) {
            const credit_value = credit_items.map((i) => i.original_price_per_case * i.number_of_cases).reduce((a, b) => a + b, 0) * -1
            value_details.push({
                label: $t('order.details.credit.label'),
                value: format_money_with_symbol(credit_value, order.sales_order.was_sold_in),
            })
        }

        const additional_items = order.sales_order_additionals
        if (additional_items.length) {
            const additional_value = order.sales_order_additionals.map((i) => i.quantity * Number(i.price_per_unit)).reduce((a, b) => a + b, 0)
            value_details.push({
                label: $t('order.details.additionals'),
                value: format_money_with_symbol(additional_value, order.sales_order.was_sold_in),
            })
        }

        if (stock_items.length) {
            value_details.push({
                label: $t('order.details.items.stock'),
                value: format_money_with_symbol(stock_value, order.sales_order.was_sold_in),
            })
        }

        return value_details
    }

    async repeat_order(order: OrderType) {
        this.data.loading = true
        await api.post<SalesOrderDetailsResponse>(`portal/orders/${order.sales_order.artkey}/repeat-order`, {}, true)
        const {sales_order} = await $m.order.load_current_order() as any
        notifier.notify($t('notifications.order_repeated', {order: sales_order.artkey}), 'info', 5000)
        this.data.loading = false
        m.route.set(`/orders/${$s.cart.artkey}/checkout`)
    }

    view() {
        const is_cart_order = ($s.cart.artkey === this.artkey)

        return <div className="c-order-view view-container">
            <div className="view">
                <div className="view-header">
                    <Breadcrumbs />
                    <div className="view-actions">
                        <ButtonGroup>
                            <ButtonDataCard/>

                            {(() => {
                                if (this.data.loading) return
                                const elements:any = []

                                // Promotion sales order only includes additionals
                                // and doesn't need these options.
                                if (this.is_promotion) return
                                if (!is_cart_order) {
                                    elements.push((
                                        <Button
                                            icon='copy'
                                            onclick={() => this.repeat_order(this.data.order)}
                                            text={$t('order.details.actions.repeat_order')}
                                            tip={$t('order.details.actions.repeat_order_tip')}
                                            type='info'
                                            variant='context'
                                        />
                                    ))
                                }

                                if (!this.data.legacy_order) {
                                    // Old legacy orders have incorrect account details listed
                                    // when generating pdfs (they would use the new account info),
                                    // so we disable this function for these old orders.
                                    if (this.data.order.sales_order.portal_status === 'Invoiced') {
                                        elements.push(
                                            <Button
                                                icon='invoice'
                                                onclick={() => this.download_invoice(this.data.order)}
                                                text={$t('order.details.actions.download_invoice')}
                                                type='info'
                                                variant='context'
                                            />,
                                            <Button
                                                icon='delivery'
                                                onclick={ () => this.download_outtake(this.data.order)}
                                                text={$t('order.details.actions.download_delivery_note')}
                                                type='info'
                                                variant='context'
                                            />,
                                        )
                                    } else if (['Finalized', 'Confirmed'].includes(this.data.order.sales_order.portal_status)) {
                                        elements.push(
                                            <Button
                                                icon='pdf'
                                                onclick={() => this.download_proforma(this.data.order)}
                                                text={$t('order.details.actions.download_proforma')}
                                                type='info'
                                                variant='context'
                                            />,
                                            <Button
                                                icon='excel'
                                                onclick={() => this.download_excel_proforma()}
                                                text={$t('order.details.actions.download_order_note')}
                                                type='info'
                                                variant='context'
                                            />,
                                        )
                                    }
                                }

                                return elements
                            })()}
                        </ButtonGroup>
                    </div>
                </div>

                {this.confirm_order && <div className={'c-order-congratulations'}>
                    <div className="alert alert-success order-alert mb-3">
                        <div className="order-text">
                            <div className="title">{$t('order.confirmed.title')}</div>
                            <div className="subtitle">{this.is_promotion ? $t('promotions.confirmed.subtitle') : $t('order.confirmed.subtitle')}</div>
                            <div className="description mb-2">
                                {this.is_promotion ? $t('promotions.confirmed.description') : $t('order.confirmed.description')}
                                {this.is_promotion && <Button
                                    active={$s.context.name === 'data_card'}
                                    className="mt-1"
                                    icon="list"
                                    link={'/my-portal/promotions'}
                                    text={$t('promotions.label.show_promotion')}
                                    type="success"
                                />}
                            </div>
                            {(this.confirm_order && !this.is_promotion) && $s.promotion.active && <div className="promotion-reminder">
                                <div className="description mb-2">
                                    {$s.promotion.tokens_draft > 0 ? $t('promotions.order_reminder_eligible') : $t('promotions.order_reminder', {token_value: $s.promotion.token_value})}
                                </div>
                                <IncentivePromotion />
                            </div>}
                        </div>
                    </div>

                    {/* The rating widget is not visible when placing a promotion sales order or when viewing orders from a legacy account */}
                    {!this.is_promotion && !this.data.legacy_order && <Rating
                        className="mb-3"
                        model={this.data.feedback}
                        on_submit={async() => {
                            await api.put(`portal/orders/${this.artkey}/rating`, this.data.feedback)
                        }}
                    />}
                </div>}

                {!this.data.loading && <Details
                    confirm_order={this.confirm_order}
                    is_promotion={this.is_promotion}
                    order={this.data.order}
                />}
            </div>
            <PanelContext>
                <div className="content">
                    {(() => {
                        if (!this.data.order) return <Spinner />
                        const order = this.data.order as any
                        const value_details = this.value_details(order)
                        return <DataCard model={{
                            data: [
                                {
                                    label: $t('order.details.created_by'),
                                    value: (order.invoice_details.was_created_by && order.invoice_details.was_created_by.contact_person) ? order.invoice_details.was_created_by.contact_person.name : config.account_name,
                                },
                                {label: $t('order.details.date'), value: format_date(order.sales_order.created_on)},
                                {label: $t('order.details.invoice_number'), value: order.sales_order.invoice_number},
                                {label: $t('order.details.invoice_date'), value: order.sales_order.is_invoiced_on ? format_date(order.sales_order.is_invoiced_on) : ''},
                                {label: $t('order.details.cases'), value: order.sales_order.number_of_cases},
                                {label: $t('order.details.order_number'), value: order.sales_order.reference},
                                {label: $t('order.details.order_status'), value: $t(`orders.portal_status.${order.sales_order.portal_status}`)},
                                {label: $t('order.details.total_value'), value: format_money_with_symbol(order.sales_order.was_sold_for, order.sales_order.was_sold_in)},
                                {label: $t('order.details.vat'), value: format_money_with_symbol(order.sales_order.vat_total, order.sales_order.was_sold_in)},
                                ...value_details,
                            ],
                        }}/>
                    })()}
                </div>
            </PanelContext>
        </div>
    }
}
